






import SKRouterView from '@/components/SK-RouterView.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    SKRouterView
  }
})
export default class PositionsInterim extends Vue {}
